import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import TabsSection from "@components/orangetabs-section/layout-1";
import CtaArea from "@containers/cta/layout-02";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import FaqArea from "@containers/faq/layout-03";
import ContactArea from "@containers/contact-us";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";

const BroadCastPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Premium Media Translation & Localization Services"
        description="Premium Media Translation & Localization Services by Andovar Localization Company. Globalize your audience by localizing your media."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["broad-cast-page-header"]} />
        <QuoteForm />
        <PartnerArea data={content["broad-cast-logo"]} />
        <FeatureGrid data={content["broad-cast-features"]} />
        <IntroArea layout={2} data={content["broad-cast-intro"]} />
        <Slider3D data={content["broadcast-media-applications"]} />
        <IntroAreaReverse
          layout={2}
          data={content["options-broadcast-intro"]}
        />
        <TabsSection data={content["broad-caststudio-body"]} />
        <CtaArea data={content["cta-data"]} />
        <IntroArea layout={2} data={content["broad-cast-auto"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["broad-cast-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query broadCastPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "broad-cast-media" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

BroadCastPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default BroadCastPage;
